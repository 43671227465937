.embed-responsive .card-img-top {
  object-fit: cover;
  width: 100%;
  height: 190px;
}

.title {
  margin-top: 5px;
  margin-bottom: 5px;
}

.body {
  margin-bottom: 5px;
}

.card-styles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.colorCard {
  background-color: #c0fae6;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-styles:hover {
  border-color: #00a35c;
  border-width: 1.5px;
}

a {
  text-decoration: none;
}

.lowerImage {
  padding-top: 10%;
}
